<!--
 * @Description: 开票申请
 * @Author: ChenXueLin
 * @Date: 2021-09-26 08:59:21
 * @LastEditTime: 2021-09-26 09:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div class="content-view section-wrapper"></div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped></style>
